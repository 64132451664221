var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "selectUserBox" }, [
    _c(
      "div",
      { staticClass: "allUserList" },
      [
        _c("el-input", {
          attrs: { placeholder: "输入关键字" },
          model: {
            value: _vm.filterText,
            callback: function ($$v) {
              _vm.filterText = $$v
            },
            expression: "filterText",
          },
        }),
        _vm._v(" "),
        _c("el-tree", {
          ref: "tree",
          staticClass: "filter-tree",
          attrs: {
            data: _vm.depts,
            "filter-node-method": _vm.filterNode,
            "default-expanded-keys": ["1"],
            props: Object.assign(
              {},
              { label: _vm.treeLabel },
              _vm.defaultProps
            ),
            "node-key": "id",
            "show-checkbox": _vm.showCheckbox,
          },
          on: { check: _vm.handleCheck, "node-click": _vm.selectUser },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "userList" },
      _vm._l(_vm.userList, function (item, index) {
        return _c(
          "el-tag",
          {
            key: index,
            staticStyle: { margin: "4px 8px" },
            attrs: { closable: "" },
            on: {
              close: function ($event) {
                return _vm.removePeople(item)
              },
            },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(item.name ? item.name : item.nickName) +
                "\n    "
            ),
          ]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }