<template>
  <el-dialog
    title="选择社群"
    :visible.sync="isshowAssociation"
    width="60%"
    top="8vh"
    :before-close="closedAssociationDialong"
    :close-on-click-modal="false"
  >
    <div class="dialogMain">
      <div class="left">
        <el-form :inline="true" class="demo-form-inline">
          <!-- <el-form-item>
            <el-date-picker
              v-model="pickerData"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              align="right"
            >
            </el-date-picker>
          </el-form-item> -->
          <el-form-item>
            <el-select
              v-model="associationParams.corpId"
              clearable
              placeholder="请选择主体"
            >
              <el-option
                v-for="(item, index) in corpList"
                :key="item.corpId"
                :label="item.enterprise"
                :value="item.corpId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input
              v-model="associationParams[selectVal]"
              clearable
              placeholder="请输入"
            >
              <el-select
                slot="prepend"
                v-model="selectVal"
                placeholder="请选择"
                @change="associationParams.search = ''"
                style="width: 150px"
              >
                <!-- <el-option label="创建人" value="fzrName"></el-option> -->
                <el-option label="社群名称" value="search"></el-option>
              </el-select>
            </el-input>
          </el-form-item>

          <el-button
            type="primary"
            @click="
              associationParams.page = 1;
              getGroupchatList();
            "
            icon="el-icon-search"
            >查询</el-button
          >
        </el-form>
        <el-s-table
          ref="associationRefs"
          :columns="columnsAssociationSel"
          :data="associationList"
          :header-row-class-name="!multiple ? 'noneCheck' : ''"
          row-key="chatId"
          height="500px"
          border
          @selection-change="handleSelectionChange"
        >
        </el-s-table>
        <!-- 分页 -->
        <div class="pagebox">
          <el-pagination
            @size-change="handleSizeChangeAssociation"
            @current-change="handleCurrentChangeAssociation"
            :current-page="associationParams.page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="associationParams.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalAssociation"
            background
          ></el-pagination>
        </div>
      </div>
      <div class="right">
        <div>
          <span>已选择</span>
          <el-button
            type="text"
            @click="
              selectData = [];
              associationTags = [];
              $refs.associationRefs.clearSelection();
            "
            >全部取消</el-button
          >
        </div>
        <div class="tagMain">
          <el-tag
            closable
            v-for="item in associationTags"
            :key="item.chatId"
            @close="tagClose(associationTags, item.chatId, 'chatId')"
            >{{ item.name }}</el-tag
          >
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closedAssociationDialong">取 消</el-button>
      <el-button type="primary" @click="submitAssociation">确认</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  getMain, // 获取主体
  getGroupchatList, // 获取客户群统计数据
  getGroupchatSelectBy,
} from "@/api/wxData/index";
import { parseTime } from "@/utils/index.js";
export default {
  props: ["socialNetNameVal", "multiple"],
  name: "",
  data() {
    return {
      isshowAssociation: false,
      associationParams: {
        beginDate: "1970-01-01",
        endDate: parseTime(new Date(), "{y}-{m}-{d}"),
        page: 1,
        size: 10,
        corpId: "",
        search: "",
        idsStr: "",
      },
      selectVal: "search",
      // pickerData: [],
      // pickerOptions: {
      //   shortcuts: [
      //     {
      //       text: "最近一周",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //     {
      //       text: "最近一个月",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //     {
      //       text: "最近三个月",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //   ],
      // },
      columnsAssociationSel: [
        {
          type: "selection",
          "reserve-selection": true,
          selectable: (row, index) => {
            if (!this.multiple && this.selectData.length) {
              return false;
            } else {
              return true;
            }
          },
          width: 50,
        },
        {
          prop: "chatId",
          label: "群ID",
        },
        {
          prop: "name",
          label: "群名",
        },
        {
          prop: "fzrName",
          label: "创建人",
        },
      ],
      corpList: [], //主体列表
      associationList: [], //社群列表
      selectData: [],
      totalAssociation: 0,
      not_foundItem: [],
      associationTags: [],
    };
  },
  created() {
    this.getCorpList();
  },
  methods: {
    closedAssociationDialong() {
      this.$refs.associationRefs.clearSelection();
      this.associationTags = [];
      this.isshowAssociation = false;
      this.associationParams = {
        beginDate: "1970-01-01",
        endDate: parseTime(new Date(), "{y}-{m}-{d}"),
        page: 1,
        size: 10,
        corpId: "",
        search: "",
        idsStr: "",
      };
    },
    // 获取主体
    getCorpList() {
      getMain().then((res) => {
        this.corpList = res;
        this.corpList.unshift({ corpId: "", enterprise: "全部" });
      });
    },
    // 获取回显数据
    getGroupShowData() {
      let idsStr = [];
      this.socialNetNameVal.forEach((item) => {
        idsStr.push(item.chatId ? item.chatId : item);
      });
      getGroupchatSelectBy({
        beginDate: "1970-01-01",
        endDate: parseTime(new Date(), "{y}-{m}-{d}"),
        page: 1,
        size: idsStr.length,
        idsStr: idsStr.toString(),
      }).then((res) => {
        if (res.list) {
          res.list.forEach((items) => {
            this.$refs.associationRefs.toggleRowSelection(items, true);
          });
          this.associationTags = res.list;
        }
      });
    },
    //获取社群列表
    getGroupchatList() {
      getGroupchatSelectBy(this.associationParams).then((res) => {
        if (res.list) {
          this.associationList = res.list ? res.list : [];
          this.$nextTick(() => {
            // Rewrite the code to avoid errors and ensure proper rendering
            // console.log(this.socialNetNameVal);
            // if (!this.socialNetNameVal.length || !this.selectData.length) {
            //   this.selectData = [];
            //   this.associationTags = [];
            //   this.$refs.associationRefs.clearSelection();
            // }
            this.socialNetNameVal.forEach((item) => {
              let foundItem = this.associationList.find((items) => {
                return (item.chatId ? item.chatId : item) == items.chatId;
              });
              if (foundItem) {
                this.$refs.associationRefs.toggleRowSelection(foundItem, true);
              }
            });
            this.not_foundItem = this.socialNetNameVal.filter((item) => {
              return !this.associationList.some((listItem) => {
                return (item.chatId ? item.chatId : item) == listItem.chatId;
              });
            });
          });
          this.totalAssociation = res.total;
        } else {
          this.$message.error(res.message ? res.message : "未查询到群数据");
        }
      });
    },
    //新增
    handleSelectionChange(val) {
      // this.selectData = this.selectData.concat(val);
      this.selectData = val;
      this.selectData = this.removeDuplicates(this.selectData, "chatId");
      if (this.selectData.length > this.associationTags.length) {
        this.selectData.forEach((item) => {
          this.associationTags.push(item);
        });
      } else {
        this.associationTags = this.associationTags.filter((tag) => {
          return this.selectData.some((item) => {
            return JSON.stringify(item) === JSON.stringify(tag);
          });
        });
      }
      this.associationTags = this.removeDuplicates(
        this.associationTags,
        "chatId"
      );
    },
    handleSizeChangeAssociation(val) {
      this.associationParams.size = val;
      this.associationParams.page = 1;
      this.getGroupchatList();
    },
    handleCurrentChangeAssociation(val) {
      this.associationParams.page = val;
      this.getGroupchatList();
    },
    submitAssociation() {
      this.associationIds = [];
      for (let i = 0; i < this.selectData.length; i++) {
        this.associationIds.push({
          chatId: this.selectData[i].chatId,
        });
      }
      this.$emit("associationData", this.associationIds);
      this.closedAssociationDialong();
    },
    removeDuplicates(array, field) {
      return array.filter((item, index, self) => {
        return index === self.findIndex((t) => t[field] === item[field]);
      });
    },
    tagClose(arr, id, key = "id") {
      let index = arr.findIndex((item) => item[key] === id);
      if (index !== -1) {
        arr.splice(index, 1);
        if (this.selectData[index]) {
          this.$refs.associationRefs.toggleRowSelection(this.selectData[index]);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialogMain {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .right {
    margin-top: 18px;
    width: 28%;
    .tagMain {
      padding-left: 5px;
      height: 500px;
      overflow-y: scroll;
      border: 1px solid #dfe6ec;
      .el-tag {
        margin-right: 5px;
      }
    }
  }
  ::v-deep .el-table .noneCheck th.el-table__cell > .cell {
    .el-checkbox {
      display: none;
    }
  }
}
</style>
