<template>
  <div id="ImportUser">
    <el-dialog
      title="导入用户"
      :visible.sync="visible"
      :close-on-click-modal="false"
      @closed="closed"
      @before-close="dialogBeforeClose"
      class="dialog-box"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="导入用户" name="user"></el-tab-pane>
        <el-tab-pane label="导入记录" name="record"></el-tab-pane>
      </el-tabs>
      <el-form
        ref="importForm"
        v-if="activeName === 'user'"
        :model="importForm"
        :rules="importFormRules"
        label-width="120px"
      >
        <el-form-item label="选择导入标签" prop="tvIds">
          <el-button type="text" @click="selectUserTag">选择标签</el-button>
          <el-tag v-for="(item, index) in importForm.tvLabels" :key="index">{{
            item
          }}</el-tag>
        </el-form-item>
        <el-form-item label="导入用户" prop="file">
          <!-- <el-button type="text">导入文件</el-button><span>按unionID导入</span> -->
          <el-upload
            ref="upload"
            accept=".xls,.xlsx"
            :action="uploadUrl"
            :data="importForm"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :before-remove="beforeRemove"
            :headers="headers"
            :auto-upload="false"
            :file-list="fileList"
            :limit="limit"
            :on-exceed="handleExceed"
            :on-change="handleChang"
          >
            <el-row type="flex" justify="start" align="center">
              <el-col :offset="0" :span="5">
                <el-button size="small" type="primary">选择文件</el-button>
              </el-col>
            </el-row>
          </el-upload>
          <el-row type="flex" justify="start" align="center">
            <el-col :offset="0" style="line-height: 2.5; text-align: left"
              >选择导入文件（1.导入文件格式：xlsx,xls;2.按unionID导入）<el-link
                href="https://hxltest.oss-cn-hangzhou.aliyuncs.com/attachments/cdp/%E6%89%8B%E5%8A%A8%E6%A0%87%E7%AD%BE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
                target="_blank"
                type="primary"
                >模板下载</el-link
              ></el-col
            >
          </el-row>
        </el-form-item>
      </el-form>
      <el-s-table v-else :columns="columns" :data="tableData" border>
        <template v-slot:default="scope">
          <el-button
            size="mini"
            type="primary"
            v-if="scope.row.success > 0"
            @click="downLoad(scope.row, scope.row.filename)"
            >查看源文件</el-button
          >
          <el-button
            size="mini"
            type="primary"
            v-if="scope.row.failure > 0"
            @click="downLoad(scope.row, scope.row.filename)"
            >下载错误报告</el-button
          >
        </template>
      </el-s-table>

      <!-- 分页 -->
      <div class="pagebox">
        <el-pagination
          v-if="total > 10 && activeName == 'record'"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
        >
        </el-pagination>
      </div>
      <div slot="footer" v-if="activeName === 'user'">
        <el-button type="primary" @click="submitForm">导入</el-button>
      </div>
    </el-dialog>
    <selectTagVue
      ref="selectTagRef"
      :isRadio="true"
      @selectTags="selectTags"
    ></selectTagVue>
  </div>
</template>
<script>
import selectTagVue from "@/views/userCenter/userList/components/selectTag.vue";
import { getToken } from "@/utils/auth.js"; // getToken from cookie
import { downloadFileNew } from "@/utils/index";
import {
  listImportedAction,
  exportManualTaggedUser,
  importManualTaggedUser,
} from "@/api/labelManagement/index.js";
// import { getImportantList } from "@/api/common-server.js";
export default {
  components: { selectTagVue },
  props: {
    headers: {
      //文件上传自定义请求头信息
      type: Object,
      default: function () {
        return {
          Authorization: getToken(),
        };
      },
    },
    // uploadUrl: {
    //   //导入地址
    //   type: String,
    //   default: "",
    // },
  },
  data() {
    return {
      visible: false,
      activeName: "user",
      fileList: [],
      columns: [
        { prop: "filename", label: "文件名称", "min-width": "200" },
        // { prop: "importNumber", label: "文件总数" },
        // { prop: "handleProgress", label: "导入进度" },
        { prop: "success", label: "成功数量" },
        { prop: "failure", label: "失败数量" },
        { prop: "updateTime", label: "导入时间" },
        { prop: "default", label: "操作", "min-width": "260" },
      ],
      tableData: [],
      total: 0,
      params: {
        page: 1,
        pageSize: 10,
      },
      uploadUrl:
        process.env.VUE_APP_BASE_API +
        "/cdp-portrait/pc/tag_info/importManualTaggedUser",
      selectFile: false,
      importForm: {
        file: null,
        tvIds: [],
        tvLabels: [],
        mode: 1,
      },
      importFormRules: {
        tvIds: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.length == 0) {
                callback(new Error("请选择标签"));
              } else {
                callback();
              }
            },
          },
        ],
        file: [
          {
            required: true,
            validator: (rule, value, callback) => {
              console.log(value, "validator");
              if (value == null) {
                callback(new Error("请选择文件")); // 返回错误信息
              } else {
                this.selectFile = true;
                callback();
              }
            },
          },
        ],
      },
      limit: 1,
      tagBizType: "",
    };
  },
  methods: {
    handleClick() {
      // this.params.sign = data;
      if (this.activeName === "user") return;
      this.getbaseImportList();
    },
    closed() {
      this.fileList = []; //上传的导入文件置空
      this.importForm = {
        file: null,
        tvIds: [],
        tvLabels: [],
        mode: 1,
      };
    },
    dialogBeforeClose() {
      this.$emit("close");
    },
    handlePreview(file) {
      console.log(file, "handlePreview");
      this.$emit("handlePreview", {
        file: file,
      });
    },
    handleRemove(file, fileList) {
      console.log(file, "handleRemove");
      this.selectFile = false;
      // this.$emit("handleRemove", {
      //   file: file,
      //   fileList: fileList,
      // });
    },
    beforeRemove(file, fileList) {
      console.log(file, "beforeRemove");
      // this.$emit("beforeRemove", {
      //   file: file,
      //   fileList: fileList,
      // });
    },
    handleExceed(files, fileList) {
      console.log(files, "handleExceed");
      // this.$emit("handleExceed", {
      //   file: files[0],
      //   fileList: fileList,
      // });
    },
    handleSuccess(response, file, fileList) {
      if (response.code != 200) this.$message.error(response.message);
      this.$refs.upload.clearFiles();
      this.yearMonth = "";
      this.importForm = {
        file: null,
        tvIds: [],
        tvLabels: [],
        mode: 1,
      };
      this.visible = false;
      this.$emit("handleSuccess");
      // this.importForm.file = file;
      // this.$emit("handleSuccess", {
      //   response: response,
      //   file: file,
      //   fileList: fileList,
      // });
    },
    handleChang(file, fileList) {
      console.log(file, "handleChang");
      // 仅上传一个文件 多次选择文件时 覆盖之前的文件
      if (this.limit == 1) {
        if (fileList.length > 0) {
          this.fileList = [fileList[fileList.length - 1]]; //这一步，是 展示最后一次选择文件
          this.$set(this.importForm, "file", file);
        }
      }
      this.selectFile = true;
    },
    submitForm() {
      this.$refs.importForm.validate((valid) => {
        if (valid) {
          this.visible = false;
          this.$refs.upload.submit();
        } else {
          return false;
        }
      });
    },
    selectUserTag() {
      this.$refs.selectTagRef.selectTagList = [];
      this.$refs.selectTagRef.visibleDialog = true;
      this.$refs.selectTagRef.params.tagBizType = this.tagBizType;
      this.$refs.selectTagRef.getTagGroup();
      this.$refs.selectTagRef.getTagListData();
      for (let i in this.importForm.tvIds) {
        this.$refs.selectTagRef.selectTagList.push({
          tagId: this.importForm.tvIds[i],
          valueName: this.importForm.tvLabels[i],
        });
      }
    },
    selectTags(val) {
      this.importForm.tvIds = [];
      this.importForm.tvLabels = [];
      this.params.tagType = val.tagType;
      val.selectTagList.forEach((item) => {
        this.importForm.tvIds.push(item.tagId);
        this.importForm.tvLabels.push(item.valueName);
      });
    },
    getbaseImportList() {
      listImportedAction(this.params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    downLoad(row, name) {
      let a = document.createElement("a");
      a.style = "display: none"; // 创建一个隐藏的a标签
      a.download = name;
      a.href =
        process.env.VUE_APP_BASE_API +
        "cdp-portrait/pc/tag_info/no-auth/exportManualTaggedUser?aid=" +
        row.id;
      document.body.appendChild(a);
      a.click(); // 触发a标签的click事件
      document.body.removeChild(a);
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.params.page = 1;
      this.getbaseImportList();
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getbaseImportList();
    },
    handelClose() {
      this.isRecord = false;
    },
  },
};
</script>
