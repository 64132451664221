var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择用户",
        visible: _vm.selectUserShow,
        top: "10vh",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.selectUserShow = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.corpSelectShow,
              expression: "corpSelectShow",
            },
          ],
          staticClass: "userSerch",
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { inline: "" } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择" },
                      on: {
                        change: function ($event) {
                          return _vm.searchBtn()
                        },
                      },
                      model: {
                        value: _vm.searchType,
                        callback: function ($$v) {
                          _vm.searchType = $$v
                        },
                        expression: "searchType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "按主体展示", value: 1 },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "按组织架构展示", value: 2 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "250px" },
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        filterable: "",
                      },
                      on: { change: _vm.getList },
                      model: {
                        value: _vm.corpList,
                        callback: function ($$v) {
                          _vm.corpList = $$v
                        },
                        expression: "corpList",
                      },
                    },
                    _vm._l(_vm.allCorpList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.corpName, value: item.corpId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入关键字" },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "selectUserBox" }, [
        _c("div", { staticClass: "allUserList" }, [
          _c(
            "div",
            { staticClass: "tree_box" },
            [
              _vm.searchType === 1
                ? _c("el-tree", {
                    key: "searchType1",
                    ref: "tree1",
                    staticClass: "filter-tree",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.allCorpUserList,
                      "filter-node-method": _vm.filterNode,
                      "default-expanded-keys": ["1"],
                      "node-key": "id",
                      "show-checkbox": _vm.showCheckbox,
                    },
                    on: {
                      "check-change": _vm.checkChange1,
                      "node-click": _vm.nodeClick,
                    },
                  })
                : _vm.searchType === 2
                ? _c("el-tree", {
                    key: "searchType2",
                    ref: "tree",
                    staticClass: "filter-tree",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.allUserList,
                      "filter-node-method": _vm.filterNode,
                      "default-expanded-keys": ["1"],
                      "node-key": "id",
                      "show-checkbox": _vm.showCheckbox,
                    },
                    on: {
                      "check-change": _vm.checkChange,
                      "node-click": _vm.nodeClick,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "userList" }, [
          _c("div", { staticClass: "userSelectList" }, [_vm._v("已选择")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tag_box" },
            _vm._l(_vm.userList, function (item, index) {
              return _c(
                "el-tag",
                {
                  key: index,
                  staticStyle: { margin: "4px 8px" },
                  attrs: { closable: "" },
                  on: {
                    close: function ($event) {
                      return _vm.removePeople(item)
                    },
                  },
                },
                [_vm._v("\n          " + _vm._s(item.label) + "\n        ")]
              )
            }),
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.selectUserConfirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }