import request from "@/utils/request";
//公共字典
export function mapList(data) {
  return request({
    url: "common-server/pc/dict-detail/map",
    method: "get",
    params: data,
  });
}
// 获取项目list   /project/list
export function getProjectList(data) {
  return request({
    url: "curriculum/pc/project/list",
    method: "get",
    params: data,
  });
}

// 获取产品线list  /pc/guanYiProduct/list
export function getGuanYiProductList(data) {
  return request({
    url: "inner-crm/pc/guanYiProduct/list",
    method: "get",
    params: data,
  });
}

// 获取所有企微主体
export function getSubjectList() {
  return request({
    url: "inner-crm/pc/friendsCircle/getAllCorp",
    method: "get",
  });
}

// 获取对应主体下的企微用户
export function getCropUser(data) {
  return request({
    url: "inner-crm/pc/user/get-user",
    method: "get",
    params: data,
  });
}
// 没有选择主体的情况下调用获取所有企微用户
export function getDeptWechatsAll(data) {
  return request({
    url: "inner-crm/pc/friendsCircle/getDeptUserAll",
    method: "get",
    params: data,
  });
}
/**
 * @title 获取所有部门用户
 * @returns
 */
export function getDeptUserAll(data) {
  return request({
    url: "inner-crm/pc/performanceCalculate/getDeptUserAll",
    method: "get",
    params: data,
  });
}
// 小鵝通優惠券
export function xiaoeCouponList(data) {
  return request({
    url: "cdp-portrait/pc/coupon/list",
    method: "get",
    params: data,
  });
}

// 小鹅通优惠券单个获取数据
export function xiaoeCouponSelectList(data) {
  return request({
    url: "inner-crm/pc/xiaoe-coupon/select-list",
    method: "post",
    data: data,
  });
}

//获取小鵝通课程列表
export function getCurriculumList(data) {
  return request({
    url: "inner-crm/pc/baseInformation/xiaoeCurriculumList",
    method: "get",
    params: data,
  });
}
