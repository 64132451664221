<template>
  <div class="main" id="main">
    <el-form :inline="true">
      <el-form-item label="注册日期">
        <el-date-picker
          type="daterange"
          value-format="yyyy-MM-dd"
          v-model="fromDate"
          :picker-options="pickerOptions"
          @change="changeDate"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="center"
        />
      </el-form-item>
      <el-form-item label="用户标签">
        <el-button
          @click="selectUserTag"
          @mouseenter.native="mouseEnter = true"
          @mouseleave.native="mouseEnter = false"
        >
          <span>{{
            tagValueIds.length
              ? "已选择" + tagValueIds.length + "个标签"
              : "点击选择标签"
          }}</span>
          <i
            v-show="mouseEnter && tagValueIds.length"
            class="el-icon-error"
            @click.stop="
              params.tagType = '';
              tagValueIds = [];
              tagValueNames = [];
            "
            @mouseenter="mouseEnter = true"
            @mouseleave="mouseEnter = false"
          ></i>
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-select v-model="params.keyType" style="width: 130px" clearable>
          <el-option label="用户名" value="1" />
          <el-option label="手机号" value="2" />
          <el-option label="用户ID" value="3" />
          <el-option label="收货人手机号" value="4" />
          <el-option label="最近采集手机号" value="5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="params.key"
          placeholder="请输入关键词"
          style="width: 200px"
          clearable
        />
      </el-form-item>
      <!-- <el-form-item label="手机号">
        <el-input
          v-model="params.phone"
          placeholder="手机号"
          style="width: 200px"
          clearable
          @clear="getList(1)"
        />
      </el-form-item>
      <el-form-item label="用户名">
        <el-input
          v-model="params.nickName"
          placeholder="用户名"
          style="width: 200px"
          clearable
          @clear="getList(1)"
        />
      </el-form-item> -->

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >查询</el-button
        >
      </el-form-item>

      <el-form-item>
        <el-button
          :loading="downloadLoading"
          type="success"
          icon="el-icon-download"
          @click="exportvisible = true"
          v-permission="['userList:export']"
          >导出</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button
          type="success"
          @click="synchronous"
          v-permission="['userList:synchronous']"
          >同步报名</el-button
        >
      </el-form-item>
      <el-form-item>
        <filter-column :columns.sync="columns" @refresh="getList()" />
      </el-form-item>
    </el-form>
    <el-s-table
      :data="tableData"
      :columns="columns"
      border
      @selection-change="handleSelectionChange"
      fix-window
    >
      <template v-slot:status="scope">
        <p :class="{ danger: scope.row.status == 1 }">
          {{ status[scope.row.status] }}
        </p>
      </template>
      <template v-slot:bindPhone="{ row }">
        <DecryptPhone
          :key="'DecryptPhone' + row.id"
          :phone.sync="row.bindPhone"
          :encryptPhone="row.encryptionBindPhone"
        />
      </template>
      <template v-slot:source="{ row }">
        <span>{{ filterLabel(userSourceList, row.source) }}</span>
      </template>
      <template v-slot:mobileType="{ row }">
        <span>{{ filterLabel(mobileSystemList, row.mobileType) }}</span>
      </template>
      <template v-slot:username="{ row }">
        <div class="mt-3">
          <div class="flex justify-center">
            <img class="w-10 h-10 rounded" :src="row.avatarUrl" />
          </div>
          <div>{{ row.nickname }}</div>
        </div>
      </template>
      <template v-slot:tags="{ row }">
        <el-tag effect="dark" v-for="(item, index) in row.tags" :key="index">{{
          item
        }}</el-tag>
      </template>
      <template v-slot:default="scope">
        <div>
          <el-button
            size="mini"
            type="primary"
            @click="
              openPage('userCenterDetail', {
                id: scope.row.huid,
                pid: scope.row.puid,
              })
            "
            v-permission="['userList:detail']"
            >详情</el-button
          >
          <el-button
            v-if="scope.row.bindPhone"
            v-permission="['userList:unbind']"
            size="mini"
            type="primary"
            @click="unbind(scope.row)"
            >解绑</el-button
          >

          <el-button
            v-permission="['userList:isOpen']"
            v-if="scope.row.status == 0"
            size="mini"
            type="danger"
            @click="forbid(scope.row.puid, 1)"
            >禁止</el-button
          >
          <el-button
            v-permission="['userList:isOpen']"
            v-else
            size="mini"
            type="success"
            @click="forbid(scope.row.puid, 0)"
            >启用</el-button
          >
          <el-button
            v-permission="['userList:switchLiveMode']"
            size="mini"
            type="success"
            @click="showSwitchMode(scope.row)"
            >切换模式</el-button
          >
          <el-button
            v-permission="['userList:openCourse']"
            size="mini"
            type="success"
            @click="openCourseBtn(scope.row)"
            >开通课程</el-button
          >
        </div>
      </template>
    </el-s-table>
    <!-- <div class="toolBar">
      <el-button type="danger" icon="el-icon-edit-outline" class="addTop">批量禁止</el-button>
    </div> -->

    <div class="pagebox">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        :total="total"
        @current-change="getList"
        @size-change="handleSizeChange"
        @prev-click="getList"
        @next-click="getList"
      />
    </div>
    <el-dialog
      width="250px"
      :visible.sync="switchLiveForm.visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <el-radio-group v-model="switchLiveForm.liveMode">
          <el-radio :label="0">默认模式，红杏林自定义</el-radio>
          <el-radio :label="1" style="margin-top: 5px"
            >其他模式，百家云原生</el-radio
          >
        </el-radio-group>
        <div slot="footer" style="text-align: right; margin-top: 10px">
          <el-button
            size="mini"
            type="text"
            @click="switchLiveForm.visible = false"
            >取消</el-button
          >
          <el-button type="primary" size="mini" @click="saveLiveModeBtn"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="用户数据导出"
      :visible.sync="exportvisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <el-form
          inline
          ref="exportForm"
          :model="exportForm"
          :rules="exportFormRule"
        >
          <el-form-item label="注册日期" prop="date">
            <el-date-picker
              :key="'exportFormDate'"
              v-model="exportForm.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="exportPickerOptions"
              align="center"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" style="text-align: right; margin-top: 10px">
          <el-button size="mini" type="text" @click="exportvisible = false"
            >取消</el-button
          >
          <el-button type="primary" size="mini" @click="download"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <OpenCourse ref="OpenCourse" />
    <selectTagVue ref="selectTagRef" @selectTags="selectTags"></selectTagVue>
  </div>
</template>

<script>
import {
  findUserList,
  editUser,
  syncUserEnroll,
  getUserLiveMode,
  saveUserLiveMode,
} from "@/api/user/index";
import { downloadCenter } from "@/api/data.js";
import crud from "@/mixins/crud";
import { userSourceList } from "./constants.js";
import OpenCourse from "./components/OpenCourse.vue";
import selectTagVue from "./components/selectTag.vue";
export default {
  name: "UserList",
  mixins: [crud],
  components: {
    OpenCourse,
    selectTagVue,
  },
  data: function () {
    let _minTime = null;
    let _maxTime = null;
    return {
      userIdList: [],
      multipleSelection: [],
      columns: [
        { type: "selection" },
        { prop: "huid", label: "用户ID" },
        { prop: "unionId", label: "微信UnionID" },
        {
          prop: "tags",
          label: "用户标签",
          width: 370,
          "show-overflow-tooltip": false,
        },
        { prop: "username", label: "用户名" },
        { prop: "bindPhone", label: "绑定手机号", width: 100 },
        { prop: "createDate", label: "注册时间" },
        { prop: "userCollectPhone", label: "最近采集手机号" },
        // { prop: "orderNumber", label: "成交" },
        { prop: "source", label: "终端来源" },
        // { prop: "mobileType", label: "系统" },
        { prop: "status", label: "用户状态" },
        { prop: "default", label: "操作", "min-width": "350", fixed: "right" },
      ],
      switchLiveForm: {
        visible: false,
        liveMode: 0,
      },
      // currentRow: {},
      searchFieldEnums: {
        // 用户名
        1: {
          key: "nickname",
        },
        // 手机号
        2: { key: "bindPhone" },
        // 用户ID
        3: { key: "huid" },
        // 收货人手机号
        4: { key: "consigneePhone" },
        // 最近采集手机号
        5: { key: "userCollectPhone" },
      },
      params: {
        key: "",
        keyType: "2",
        startDate: "",
        endDate: "",
        tagType: "",
        tagValueIds: "",
        tagValueNames: "",
      },
      tagValueIds: [],
      tagValueNames: [],
      fromDate: [],
      tableData: [],
      mobileSystemList: [
        // 系统
        { label: "安卓", value: 0 },
        { label: "IOS", value: 1 },
        { label: "未知", value: 99 },
      ],
      userSourceList,
      total: 0,
      pageSize: 10,
      status: ["正常", "已禁止"],
      pickerOptions: {
        onPick(time) {
          // 如果选择了只选择了一个时间
          if (!time.maxDate) {
            let timeRange = 90 * 24 * 60 * 60 * 1000; // 90天
            _minTime = time.minDate.getTime() - timeRange; // 最小时间
            _maxTime = time.minDate.getTime() + timeRange; // 最大时间
            // 如果选了两个时间，那就清空本次范围判断数据，以备重选
          } else {
            _minTime = _maxTime = null;
          }
        },
        disabledDate(time) {
          // onPick后触发
          // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
          if (_minTime && _maxTime) {
            return time.getTime() < _minTime || time.getTime() > _maxTime;
          }
        },
      },
      exportvisible: false,
      exportForm: {
        date: [],
      },
      exportFormRule: {
        date: [
          {
            type: "array",
            required: true,
            message: "请选择注册日期，跨度最长为31天",
            trigger: "change",
          },
        ],
      },
      exportPickerOptions: {
        onPick(time) {
          // 如果选择了只选择了一个时间
          if (!time.maxDate) {
            let timeRange = 30 * 24 * 60 * 60 * 1000; // 30天
            _minTime = time.minDate.getTime() - timeRange; // 最小时间
            _maxTime = time.minDate.getTime() + timeRange; // 最大时间
            // 如果选了两个时间，那就清空本次范围判断数据，以备重选
          } else {
            _minTime = _maxTime = null;
          }
        },
        disabledDate(time) {
          // onPick后触发
          // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
          if (_minTime && _maxTime) {
            return time.getTime() < _minTime || time.getTime() > _maxTime;
          }
        },
      },
      mouseEnter: false,
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    search() {
      if (
        this.params.tagType != 3 &&
        this.params.tagType != "" &&
        !this.tagValueIds.length
      ) {
        this.$message({
          type: "warning",
          message: "请选择标签值，在查询",
        });
        return;
      }
      this.getList();
    },
    filterLabel(list, value) {
      const obj = list.find((item) => item.value == value) || {};
      return obj.label || "未知";
    },
    beforeInit() {
      this.url = "/user/pc/user/downUser";
      return true;
    },
    download() {
      this.$refs.exportForm.validate((valid) => {
        if (valid) {
          const [startDate, endDate] = this.exportForm.date;
          const Odata = {
            ...this.params,
            startDate,
            endDate,
            tagValueIds: this.tagValueIds,
            tagValueNames: this.tagValueNames,
          };
          const filed = this.searchFieldEnums[this.params.keyType]?.key;
          if (filed) {
            Odata[filed] = Odata.key;
          }
          delete Odata.key;
          delete Odata.keyType;
          downloadCenter(Odata, 6).then((res) => {
            if (res.code == 200) {
              this.$router.push({
                path: "/system/downloadcenter",
              });
            } else {
              this.$notify({
                message: res.message,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getList(page = 1) {
      this.page = page;
      const filed = this.searchFieldEnums[this.params.keyType]?.key;
      const value = this.params.key;
      const tagValueIds = this.tagValueIds.toString();
      const tagValueNames = this.tagValueNames.toString();
      const Odata = {
        ...this.params,
        tagValueIds: tagValueIds,
        tagValueNames: tagValueNames,
        page: page,
        pageSize: this.pageSize,
      };
      if (filed) {
        Odata[filed] = value;
      }
      delete Odata.key;
      delete Odata.keyType;
      findUserList(Odata).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.data?.map((item) => {
            return { ...item, visible: false };
          });
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    getRowKey(row) {
      return row.userId;
    },
    forbid(id, status) {
      if (status) {
        this.$confirm("此操作将禁止该用户, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            this.editUser(id, status);
          })
          .catch(() => {});
      } else {
        this.editUser(id, status);
      }
    },
    editUser(id, status) {
      editUser({ id: id, status: status }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.getList(this.page);
        }
      });
    },
    showUserDetail(id) {
      this.$router.push({ path: "userCenterDetail", query: { id: id } });
    },
    handleSelectionChange(val) {
      this.userIdList = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach((item) => {
        this.userIdList.push(item.puid);
      });
    },
    changeDate(e) {
      console.log(e);
      if (e) {
        this.params.startDate = e[0];
        this.params.endDate = e[1];
      } else {
        this.params.startDate = "";
        this.params.endDate = "";
      }
    },
    //同步用户报名信息
    syncUserEnroll() {
      syncUserEnroll(this.userIdList).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.getList();
          this.$refs.tableRef.clearSelection();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    synchronous() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先选择要同步的用户");
        return;
      }
      this.$confirm("你确定要同步报名小鹅通课程", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.syncUserEnroll();
        })
        .catch(() => {});
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    //解绑
    unbind(row) {
      this.$router.push({
        name: "Unbind",
        query: { userId: row.puid, phone: row.bindPhone },
      });
    },
    showSwitchMode(row) {
      this.switchLiveForm.userId = row.puid;
      // this.currentRow = row;
      getUserLiveMode({ userId: row.puid }).then((res) => {
        if (res.code == 200) {
          this.switchLiveForm.liveMode = res.data.liveMode;
          this.switchLiveForm.visible = true;
          return;
        }
        this.$message.error(res.message);
      });
    },
    saveLiveModeBtn() {
      // const { visible } = this.switchLiveForm;
      saveUserLiveMode({
        ...this.switchLiveForm,
        // userId: this.currentRow.userId,
      }).then((res) => {
        if (res.code == 200) {
          this.switchLiveForm.visible = false;
          this.$message.success(res.message);
          return;
        }
        this.$message.error(res.message);
      });
    },
    // 开通课程
    openCourseBtn(row) {
      this.$refs.OpenCourse.show(row);
    },
    // 选择标签
    selectUserTag() {
      // if (["3"].includes(this.params.tagType) || this.params.tagType == "")
      //   return;
      this.$refs.selectTagRef.selectTagList = [];
      this.$refs.selectTagRef.visibleDialog = true;
      this.$refs.selectTagRef.getTagGroup();
      this.$refs.selectTagRef.getTagListData();
      for (let i in this.tagValueIds) {
        this.$refs.selectTagRef.selectTagList.push({
          tagId: this.tagValueIds[i],
          name: this.tagValueNames[i],
        });
      }
    },
    selectTags(val) {
      this.tagValueIds = [];
      this.tagValueNames = [];
      this.params.tagType = val.tagType;
      val.selectTagList.forEach((item) => {
        this.tagValueIds.push(item.tagId);
        this.tagValueNames.push(item.name);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input .el-input__inner[name="readonly"] {
  padding: 0;
}
::v-deep .el-tag {
  margin-right: 5px;
  margin-bottom: 5px;
}
.mt-3 {
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.w-10 {
  width: 2.5rem;
}
.h-10 {
  height: 2.5rem;
}
.rounded {
  border-radius: 5px;
}
.el-icon-error {
  margin-left: 10px;
}
</style>
