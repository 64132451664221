<template>
  <div class="tag_manage">
    <div class="content">
      <div class="content_row_header">
        <slot name="father-date"></slot>
        <div class="contentBox" v-if="!father">
          <el-select
            v-model="changeData.typeValue"
            filterable
            placeholder="请选择"
            @change="rulesChange"
          >
            <el-option
              v-for="item in rulesConfig"
              :key="item.key"
              :label="item.name"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <el-select
            v-if="logicOptions && logicOptions.length"
            v-model="changeData.logicValue"
            filterable
            placeholder="请选择"
            @change="logicValueChange"
            style="width: 100px"
          >
            <el-option
              v-for="item in logicOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- 最后一级start -->
          <el-select
            ref="purchaseProductValRefs"
            v-if="changeData.typeValue == 'purchaseProduct'"
            v-model="changeData.purchaseProductVal"
            filterable
            clearable
            :multiple="changeData.logicValue == 'EQUAL' ? false : true"
            placeholder="请选择"
            collapse-tags
            style="width: 280px"
            @change="changeDataFun"
          >
            <!-- remote
            :remote-method="remoteMethod"
            @focus="selectFocus"
            v-loadmore="loadMore" -->
            <el-option
              v-for="item in tagManageData.goodsList"
              :key="item.value"
              :label="item.productName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            ref="curriculumLevelValRefs"
            v-model="changeData.curriculumLevelVal"
            filterable
            clearable
            :multiple="changeData.logicValue == 'EQUAL' ? false : true"
            collapse-tags
            placeholder="请选择"
            v-if="changeData.typeValue == 'curriculumLevel'"
            style="width: 280px"
            @change="changeDataFun"
          >
            <el-option
              v-for="item in tagManageData.dictData.curr_level"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            ref="curriculumProjectValRefs"
            v-if="changeData.typeValue == 'curriculumProject'"
            v-model="changeData.curriculumProjectVal"
            filterable
            clearable
            :multiple="changeData.logicValue == 'EQUAL' ? false : true"
            collapse-tags
            placeholder="请选择"
            style="width: 280px"
            @change="changeDataFun"
          >
            <!-- :remote-method="remoteMethod"
              remote
            @focus="selectFocus"
            v-loadmore="loadMore" -->
            <el-option
              v-for="item in tagManageData.curriculumProjectList"
              :key="item.value"
              :label="item.proRemake"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <cascader-fix
            v-if="changeData.typeValue === 'curriculumCode'"
            ref="currCascaderRef"
            popper-class="last-check"
            collapse-tags
            clearable
            filterable
            v-model="cascaderValue"
            :options="tagManageData.curriculumCodeList"
            :props="{
              multiple: changeData.logicValue == 'EQUAL' ? false : true,
              emitPath: false,
            }"
            @change="cascaderChange"
            style="width: 280px"
          ></cascader-fix>
          <el-input
            type="text"
            v-model="changeData.inputValue"
            style="width: 200px"
            v-if="
              ['collectMoney', 'monetary'].includes(changeData.typeValue) &&
              changeData.logicValue != 'BETWEEN'
            "
            @input="changeDataFun"
          />
          <el-date-picker
            v-model="changeData.value1"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            v-if="changeData.typeValue == 'eventTime'"
            @change="changeDataFun"
          >
          </el-date-picker>
          <div
            class="section_box"
            v-if="
              changeData.typeValue != 'eventTime' &&
              ![
                'curriculumLevel',
                'curriculumProject',
                'curriculumCode',
              ].includes(changeData.typeValue) &&
              changeData.logicValue === 'BETWEEN'
            "
          >
            <el-input
              type="text"
              v-model="changeData.minMoneyValue"
              style="width: 200px"
              @input="changeDataFun"
            />
            <span style="margin-right: 10px">--</span>
            <el-input
              type="text"
              v-model="changeData.maxMoneyValue"
              style="width: 200px"
              @input="changeDataFun"
            />
          </div>
          <el-button
            size="medium"
            v-if="
              [
                'resourceId',
                'couponId',
                'learningCourse',
                'clickGoods',
                'enterLive',
                'socialNetName',
              ].includes(changeData.typeValue)
            "
            @click="oepneXiaoe(changeData.typeValue)"
            >点击选择</el-button
          >
          <span
            v-if="
              [
                'resourceId',
                'couponId',
                'learningCourse',
                'clickGoods',
                'enterLive',
                'socialNetName',
              ].includes(changeData.typeValue)
            "
            >已选择{{
              [
                "resourceId",
                "learningCourse",
                "clickGoods",
                "enterLive",
                "socialNetName",
              ].includes(changeData.typeValue)
                ? changeData[changeData.typeValue].length
                : changeData.couponVal.length
            }}条数据</span
          >
          <el-select
            ref="couponValRefs"
            v-model="changeData.couponTypeVal"
            filterable
            clearable
            :multiple="changeData.logicValue == 'EQUAL' ? false : true"
            collapse-tags
            placeholder="请选择"
            v-if="changeData.typeValue == 'couponState'"
            style="width: 280px"
            @change="changeDataFun"
          >
            <el-option label="待使用" :value="0"></el-option>
            <el-option label="已使用" :value="1"></el-option>
            <el-option label="已失效" :value="3"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!-- <div class="content_child">
      <slot name="child-view"></slot>
    </div> -->
    <xiaoeCouponVue
      ref="xiaoeCouponRefs"
      :couponVal="changeData.couponVal"
      :multiple="changeData.logicValue == 'EQUAL' ? false : true"
      @couponData="getCouponData"
    ></xiaoeCouponVue>
    <xiaoeCourseVue
      ref="xiaoeCourseRefs"
      :courseVal="changeData[changeData.typeValue]"
      :multiple="changeData.logicValue == 'EQUAL' ? false : true"
      @courseData="getCourseData"
      :key="tagManageData.fatherIndex + '-' + tagManageData.childIndex"
    ></xiaoeCourseVue>
    <Association
      ref="socialNetNameRefs"
      :socialNetNameVal="changeData.socialNetName"
      :multiple="changeData.logicValue == 'EQUAL' ? false : true"
      @associationData="getAssociation"
    ></Association>
  </div>
</template>

<script>
import xiaoeCouponVue from "@/components/xiaoeCoupon.vue";
import xiaoeCourseVue from "@/components/xiaoeCourse.vue";
import CascaderFix from "@/components/cascaderFix.vue";
import Association from "../association.vue";
export default {
  name: "tagManage",
  components: {
    xiaoeCouponVue, //小鹅通优惠券组件
    xiaoeCourseVue, //小鹅通课程组件
    CascaderFix,
    Association, //社群列表
  },
  props: ["rulesConfig", "tagManageData", "father", "changeDataFuns"],
  data() {
    return {
      defaultLogicOptions: [
        { label: "大于", value: "GREATER" },
        { label: "大于等于", value: "GREATER_EQUAL" },
        { label: "小于", value: "LESS" },
        { label: "小于等于", value: "LESS_EQUAL" },
        { label: "等于", value: "EQUAL" },
        { label: "不等于", value: "NOT_EQUAL" },
        { label: "包含", value: "CONTAIN" },
        { label: "不包含", value: "NOT_CONTAIN" },
        { label: "区间", value: "BETWEEN" },
      ],
      logicOptions: [],
      valueOptions: [],
      changeData: {
        typeValue: "",
        logicValue: "",
        purchaseProductVal: undefined,
        curriculumLevelVal: undefined,
        curriculumProjectVal: undefined,
        value1: "",
        inputValue: "",
        minMoneyValue: "",
        maxMoneyValue: "",
        config: {},
        keyType: "SIMPLE",
        cascaderValue: [],
        couponVal: [],
        resourceId: [],
        couponTypeVal: "",
        learningCourse: [],
        clickGoods: [],
        enterLive: [],
        socialNetName: [],
      },
      cascaderValue: [],
      // cascaderOptions: [],
      curriculumProps: {
        multiple: true,
        emitPath: false,
      },
    };
  },
  mounted() {
    if (this.tagManageData.data.inputs.length) {
      this.changeData.typeValue = this.tagManageData.data.dataKey;
      this.changeData.logicValue = this.tagManageData.data.operator;
      if (this.changeData.typeValue == "curriculumLevel") {
        if (this.changeData.logicValue == "EQUAL") {
          this.changeData.curriculumLevelVal =
            this.tagManageData.data.inputs[0];
        } else {
          this.changeData.curriculumLevelVal = [];
          if (this.tagManageData.data.inputs[0].length) {
            let valArr = this.tagManageData.data.inputs[0].split(",");
            for (let i = 0; i < valArr.length; i++) {
              this.changeData.curriculumLevelVal.push(valArr[i]);
            }
          }
        }
      }
      if (this.changeData.typeValue == "purchaseProduct") {
        if (this.changeData.logicValue == "EQUAL") {
          this.changeData.purchaseProductVal = Number(
            this.tagManageData.data.inputs[0]
          );
        } else {
          this.changeData.purchaseProductVal = [];
          if (this.tagManageData.data.inputs[0].length) {
            let valArr = this.tagManageData.data.inputs[0].split(",");
            for (let i = 0; i < valArr.length; i++) {
              this.changeData.purchaseProductVal.push(Number(valArr[i]));
            }
          }
        }
      }
      if (this.changeData.typeValue == "curriculumProject") {
        if (this.changeData.logicValue == "EQUAL") {
          this.changeData.curriculumProjectVal = Number(
            this.tagManageData.data.inputs[0]
          );
        } else {
          this.changeData.curriculumProjectVal = [];
          if (this.tagManageData.data.inputs[0].length) {
            let valArr = this.tagManageData.data.inputs[0].split(",");
            for (let i = 0; i < valArr.length; i++) {
              this.changeData.curriculumProjectVal.push(Number(valArr[i]));
            }
          }
        }
      }
      if (this.changeData.typeValue === "curriculumCode") {
        if (this.changeData.logicValue == "EQUAL") {
          this.cascaderValue = this.tagManageData.data.inputs[0];
        } else {
          let cascader = this.tagManageData.data.inputs[0].indexOf(",")
            ? this.tagManageData.data.inputs[0].split(",")
            : this.tagManageData.data.inputs[0];
          this.cascaderValue = cascader;
        }
        console.log("cascaderValue:", this.cascaderValue);
      }

      if (
        ["collectMoney", "monetary"].includes(this.changeData.typeValue) &&
        this.changeData.logicValue != "BETWEEN"
      ) {
        this.changeData.inputValue = this.tagManageData.data.inputs[0];
      }

      if (this.changeData.typeValue == "eventTime") {
        this.changeData.value1 = this.tagManageData.data.inputs[0];
      }

      if (
        this.changeData.typeValue != "eventTime" &&
        this.changeData.logicValue === "BETWEEN"
      ) {
        this.changeData.minMoneyValue = this.tagManageData.data.inputs[0];
        this.changeData.maxMoneyValue = this.tagManageData.data.inputs[1];
      }
      this.changeData.keyType = this.tagManageData.data.keyType
        ? this.tagManageData.data.keyType
        : "SIMPLE";
      if (
        ["resourceId", "learningCourse", "clickGoods", "enterLive"].includes(
          this.changeData.typeValue
        )
      ) {
        this.changeData[this.changeData.typeValue] =
          this.tagManageData.data.inputs[0].split(",");
      }
      if (this.changeData.typeValue === "socialNetName") {
        this.changeData.socialNetName =
          this.tagManageData.data.inputs[0].split(",");
      }
      if (this.changeData.typeValue === "couponId") {
        this.changeData.couponVal =
          this.tagManageData.data.inputs[0].split(",");
      }
      if (this.changeData.typeValue === "couponState") {
        this.changeData.couponTypeVal =
          this.changeData.logicValue == "EQUAL"
            ? Number(this.tagManageData.data.inputs[0])
            : this.tagManageData.data.inputs[0].split(",").map(Number);
      }
    }
    this.rulesChange();
  },
  filters: {},
  methods: {
    rulesChange() {
      for (let i in this.rulesConfig) {
        if (this.rulesConfig[i].key == this.changeData.typeValue) {
          this.changeData.config = this.rulesConfig[i];
          if (this.rulesConfig[i].operatorScope) {
            this.filtersLogic(this.rulesConfig[i].operatorScope);
          } else {
            this.logicOptions = [];
          }
        }
      }
    },
    // 过滤logic数据
    filtersLogic(operator) {
      this.logicOptions = this.defaultLogicOptions.filter((item) =>
        operator.includes(item.value)
      );
    },
    // 课程变更
    cascaderChange(val) {
      if (typeof val === "object" && val) {
        let cascaderList = [];
        val.forEach((item) => {
          cascaderList.push(item);
        });
        this.changeData.cascaderValue = cascaderList;
      } else {
        this.changeData.cascaderValue = val;
      }
      this.changeDataFuns({
        newVal: this.changeData,
        sourceData: this.tagManageData,
      });
    },
    changeDataFun() {
      this.changeDataFuns({
        newVal: this.changeData,
        sourceData: this.tagManageData,
      });
    },
    logicValueChange() {
      let obj = {};
      obj.stopPropagation = () => {};
      if (
        this.changeData.purchaseProductVal ||
        this.changeData.curriculumLevelVal ||
        this.changeData.curriculumProjectVal ||
        this.cascaderValue ||
        this.changeData.couponTypeVal
      ) {
        this.$refs.purchaseProductValRefs
          ? this.$refs.purchaseProductValRefs.handleClearClick(obj)
          : "";
        this.$refs.curriculumLevelValRefs
          ? this.$refs.curriculumLevelValRefs.handleClearClick(obj)
          : "";
        this.$refs.curriculumProjectValRefs
          ? this.$refs.curriculumProjectValRefs.handleClearClick(obj)
          : "";
        this.$refs.currCascaderRef
          ? this.$refs.currCascaderRef.handleClear()
          : "";
        if (this.changeData.logicValue == "EQUAL") {
          this.changeData.couponVal = [];
          this.changeData[this.changeData.typeValue] = [];
        }
      }
      this.changeData.inputValue = "";
      this.changeDataFuns({
        newVal: this.changeData,
        sourceData: this.tagManageData,
      });
    },
    // 打开小鹅通组件弹窗
    oepneXiaoe(typeValue) {
      if (typeValue === "couponId") {
        // 小鹅通优惠券列表
        this.$refs.xiaoeCouponRefs.couponVisible = true;
        this.$refs.xiaoeCouponRefs.getCouponList();
      } else if (
        ["resourceId", "learningCourse", "clickGoods", "enterLive"].includes(
          typeValue
        )
      ) {
        // 小鹅通课程列表
        this.$refs.xiaoeCourseRefs.isshowCourse = true;
        this.$refs.xiaoeCourseRefs.getCurriculumList();
      } else {
        // 获取社群列表
        this.$refs.socialNetNameRefs.isshowAssociation = true;
        this.$refs.socialNetNameRefs.getGroupchatList();
        this.$refs.socialNetNameRefs.getGroupShowData();
      }
    },
    getCouponData(data) {
      this.changeData.couponVal = [];
      data.forEach((item) => {
        this.changeData.couponVal.push(item.couId);
      });
      this.changeDataFuns({
        newVal: this.changeData,
        sourceData: this.tagManageData,
      });
    },
    getCourseData(data) {
      this.changeData[this.changeData.typeValue] = [];
      data.forEach((item) => {
        this.changeData[this.changeData.typeValue].push(item.xiaoeId);
      });
      this.changeDataFuns({
        newVal: this.changeData,
        sourceData: this.tagManageData,
      });
    },
    getAssociation(data) {
      console.log(data);
      this.changeData.socialNetName = [];
      data.forEach((item) => {
        this.changeData.socialNetName.push(item.chatId);
      });
      this.changeDataFuns({
        newVal: this.changeData,
        sourceData: this.tagManageData,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}

.tag_manage {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  .content {
    width: 100%;

    .content_row_header {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      // margin: 10px 0;
      .el-date-editor,
      .el-select,
      .el-cascader,
      .el-input {
        margin-right: 10px;
      }

      .contentBox {
        display: flex;

        ::v-deep .el-cascader {
          .el-input .el-input__inner {
            height: 32px !important;
          }

          .el-cascader__tags {
            flex-wrap: nowrap;
            align-content: center;
            align-items: center;
            width: 210px;

            .el-cascader__search-input {
              visibility: hidden;
            }
          }
        }
      }
    }

    .section_box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    p {
      margin: 0;
      padding: 0;
      float: left;
      width: 50px;
    }
  }

  .content_child {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    // margin: 10px 0;
  }
}
</style>
<style lang="scss">
.last-check {
  li[aria-haspopup="true"] {
    .el-checkbox {
      display: none;
    }
  }
}
</style>
