<template>
  <div class="selectUserBox">
    <div class="allUserList">
      <el-input placeholder="输入关键字" v-model="filterText"> </el-input>

      <el-tree
        class="filter-tree"
        :data="depts"
        :filter-node-method="filterNode"
        :default-expanded-keys="['1']"
        :props="{
          label: treeLabel,
          ...defaultProps,
        }"
        node-key="id"
        ref="tree"
        :show-checkbox="showCheckbox"
        @check="handleCheck"
        @node-click="selectUser"
      >
      </el-tree>
    </div>
    <div class="userList">
      <el-tag
        v-for="(item, index) in userList"
        :key="index"
        closable
        style="margin: 4px 8px"
        @close="removePeople(item)"
      >
        {{ item.name ? item.name : item.nickName }}
      </el-tag>
    </div>
  </div>
</template>
<script>
// import { deptUserTree } from "@/api/system/user";
export default {
  name: "SelectUser",
  props: {
    allDepts: {
      type: Array,
      default: [],
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    isRadioSelect: {
      // 是否单选
      type: Boolean,
      default: false,
    },
    defaultProps: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      filterText: "",
      depts: [],
      userList: [],
      // defaultProps: {
      //   // 单选模式下 禁选父节点
      //   disabled: (data, node) => this.isRadioSelect && data.type != 1,
      // },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    //用户选择变更
    handleCheck(data, node) {
      if (this.isRadioSelect) {
        this.radioCheck(data, node);
      } else {
        this.selectUserChange(data, node);
      }
    },
    // 获取部门数据
    getDepts(userId = null) {
      this.filterText = "";
      this.userList = [];
      this.depts = JSON.parse(JSON.stringify(this.allDepts));
      this.dataReconstitution(this.depts, this.depts[0].id);
      // console.log(this.depts);
      // deptUserTree().then((res) => {
      //   this.depts = res.data;
      //   this.dataReconstitution(this.depts[0].children);
      if (userId) {
        this.$nextTick(() => {
          userId.forEach((item) => {
            this.findAndSelectUsers(item, this.depts);
          });
        });
      }
      // });
    },
    //用户搜索筛选
    filterNode(value, data) {
      if (!value) return true;
      return data.name
        ? data.name.indexOf(value) !== -1
        : data.nickName.indexOf(value) !== -1;
    },
    treeLabel(data, node) {
      return data.name ? data.name : data.nickName;
    },
    findAndSelectUsers(itemId, depts) {
      depts.forEach((dept) => {
        if (dept.children && dept.children.length) {
          this.findAndSelectUsers(itemId, dept.children);
        } else {
          if (
            typeof dept.id === "string" &&
            dept.id.split("-")[dept.id.split("-").length - 1] === itemId
          ) {
            // console.log("findAndSelectUsers:", itemId, depts);
            this.userList.push(dept);
            this.$refs.tree.setChecked(dept.id, true);
          }
        }
      });
      this.userList = this.userUnique(this.userList);
    },
    // // 单选
    radioCheck(data, node) {
      this.$refs.tree.setCheckedKeys([data.id]);
      this.userList = [data];
    },
    //用户选择变更
    selectUserChange(data, indeterminate) {
      // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
      let selected = indeterminate.checkedKeys.indexOf(data.id); // -1未选中
      // 选中
      if (selected !== -1) {
        this.filterPeople(data, true);
        // this.selectedParent(data)
      } else {
        // 未选中 处理子节点全部未选中
        this.filterPeople(data, false);
      }
      // this.userList = lodash.uniq(this.userList);
    },
    //递归找出部门下所有人员
    filterPeople(data, isSelected) {
      // this.$refs.tree.setChecked(data.id, isSelected);
      if (data.children && data.children.length) {
        data.children.forEach((item) => {
          this.filterPeople(item, isSelected);
        });
      } else {
        if (isSelected) {
          // this.selectedParent(data);
          let currentNode = this.$refs.tree.getNode(data);
          this.filterSelectData(currentNode, this.depts, true);
          if (currentNode.parent.key !== undefined) {
            this.$refs.tree.setChecked(currentNode.parent, true);
          }
          this.$refs.tree.setChecked(currentNode, true);
          this.userList.push(data);
        } else {
          for (let i = 0; i < this.userList.length; i++) {
            if (
              typeof this.userList[i].id === "string" &&
              this.userList[i].id.indexOf("-")
            ) {
              if (
                this.userList[i].id.split("-")[
                  this.userList[i].id.split("-").length - 1
                ] == data.id.split("-")[data.id.split("-").length - 1]
              ) {
                this.filterSelectData(data, this.depts, false);
                this.userList.splice(i, 1);
              }
            }
          }
        }
      }
    },
    /**
     * @data 需要选中的数据
     * @arr 循环数组对象
     */
    filterSelectData(data, arr, boolean) {
      const filterRecursive = (data, arr) => {
        arr.forEach((item) => {
          if (
            (typeof item.id === "string" &&
              data.key &&
              data.key.split("-")[data.key.split("-").length - 1] ===
                item.id.split("-")[item.id.split("-").length - 1]) ||
            (typeof data.id === "string" &&
              typeof item.id === "string" &&
              data.id.split("-")[data.id.split("-").length - 1] ===
                item.id.split("-")[item.id.split("-").length - 1])
          ) {
            this.$refs.tree.setChecked(item.id, boolean);
          }
          if (item.children && item.children.length) {
            filterRecursive(data, item.children);
          }
        });
      };
      filterRecursive(data, arr);
    },
    //移除人员
    removePeople(data) {
      for (let i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id == data.id) {
          this.$refs.tree.setChecked(data.id, false);
          this.filterSelectData(data, this.depts, false);
          this.userList.splice(i, 1);
        }
      }
    },
    // 数据重构。将同级userList同步更新到children
    dataReconstitution(arr, parentData) {
      let result;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].label) arr[i].name = arr[i].label;
        if (arr[i].userList && arr[i].userList.length) {
          arr[i].userList.forEach((item) => {
            if (!arr[i].children) arr[i].children = [];
            arr[i].children.push({
              appId: arr[i].appId,
              createTime: arr[i].createTime,
              deptLeader: arr[i].deptLeader,
              enabled: arr[i].enabled,
              id: `${arr[i].id}-${item.id}`,
              name: item.nickName,
              appName: arr[i].appName,
              pid: arr[i].id,
              people: true,
            });
          });
        }
        if (arr[i].children && arr[i].children.length) {
          result = this.dataReconstitution(arr[i].children, arr[i]);
          if (result) {
            return result;
          }
        } else {
          arr[i].id = `${parentData.id}-${arr[i].id}`;
          arr[i].deptId = parentData.id;
          arr[i].deptName = parentData.label;
          if (arr[i].type == 0)
            arr[i].name = `${parentData.label}-${arr[i].label}`;
        }
      }
    },
    //只针对选择用户回显去重。请勿改动。如需通用去重，请使用lodash.uniq
    userUnique(arr) {
      let newArr = [];
      for (let i = 0; i < arr.length; i++) {
        if (
          !newArr.some(
            (item) =>
              item.id.split("-")[item.id.split("-").length - 1] ==
              arr[i].id.split("-")[arr[i].id.split("-").length - 1]
          )
        ) {
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    // 选择人员
    selectUser(data, node, nodeComponents) {
      this.$emit("clickSelectUser", {
        data: data,
        node: node,
        components: nodeComponents,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.selectUserBox {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-height: 590px;
  overflow: hidden;

  .allUserList {
    padding: 0 10px;
    width: 50%;
    max-height: 590px;
    overflow-y: scroll;
    border-right: 1px solid #dcdfe6;
    .filter-tree {
      width: auto !important;
    }
  }

  .userList {
    padding: 0 10px;
    width: 50%;
    max-height: 590px;
    overflow-y: scroll;

    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
      color: #333;
      line-height: 1.5;
    }
  }
}
</style>
