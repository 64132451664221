<template>
  <el-dialog
    title="选择用户"
    :visible.sync="selectUserShow"
    width="800px"
    top="10vh"
    @opened="handleOpen"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="
      () => {
        $refs.selectUserVueRefs.$refs.tree.setCheckedKeys([]);
        selectUserShow = false;
      }
    "
  >
    <selectuserVue
      ref="selectUserVueRefs"
      :showCheckbox="showCheckbox"
      :allDepts="allUserList"
      :isRadioSelect="isRadioSelect"
      :defaultProps="defaultProps"
      @clickSelectUser="selectUser"
    ></selectuserVue>
    <div slot="footer" v-if="showCheckbox">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="selectUserConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getDeptUserAll } from "@/api/hxl/public.js";
import selectuserVue from "./selectuser.vue";
export default {
  components: {
    selectuserVue,
  },
  props: {
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    isRadioSelect: {
      // 是否单选
      type: Boolean,
      default: false,
    },
    isCorpId: {
      // 是否需要corpId
      type: Boolean,
      default: false,
    },
    defaultProps: {
      type: Object,
      default: {},
    },
    // 获取微信还是获取用户id 1为用户id 2为微信
    getWechatOrUser: {
      type: Function,
      default: getDeptUserAll,
    },
  },
  data() {
    return {
      // defaultProps: {
      //   // 单选模式下 禁选父节点
      //   disabled: (data, node) => this.isRadioSelect && data.type != 1,
      // },
      selectUserShow: false,
      userList: [], //已选用户列表
      allUserList: [], //所有用户列表
      filterText: "",
      accountingDialogForm: {},
      defaultCheckedKeys: [],
    };
  },
  watch: {
    // filterText(val) {
    //   this.$refs.tree.filter(val);
    // },
  },
  methods: {
    handleOpen() {
      let userIds = [];
      this.userList.forEach((item) => {
        userIds.push(item.id.toString());
      });
      if (!this.allUserList?.length) {
        this.getWechatOrUser({
          corpId: this.isCorpId ? this.$store.state.user.user.corpId : null,
        })
          .then((res) => {
            this.allUserList = res.data || [];
            this.$nextTick(() => {
              this.$refs.selectUserVueRefs.getDepts(userIds);
            });
          })
          .catch((err) => {});
      } else {
        this.$nextTick(() => {
          this.$refs.selectUserVueRefs.getDepts(userIds);
        });
      }
    },
    // //用户选择变更
    // handleCheck(data, node) {
    //   if (this.isRadioSelect) {
    //     this.radioCheck(data, node);
    //   } else {
    //     this.selectUserChange(data, node);
    //   }
    // },

    // // 默认多选
    // selectUserChange(data, indeterminate) {
    //   // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
    //   let selected = indeterminate.checkedKeys.indexOf(data.id); // -1未选中
    //   // 选中
    //   if (selected !== -1) {
    //     // 子节点只要被选中父节点就被选中
    //     // 统一处理子节点为相同的勾选状态
    //     this.filterPeople(data, true);
    //     // this.selectedParent(data)
    //   } else {
    //     // 未选中 处理子节点全部未选中
    //     this.filterPeople(data, false);
    //   }
    // },

    // // 单选
    // radioCheck(data, node) {
    //   this.$refs.tree.setCheckedKeys([data.id]);
    //   this.userList = [data];
    // },

    // //用户搜索筛选
    // filterNode(value, data) {
    //   console.log(value, data);
    //   if (!value) return true;
    //   return data.label.indexOf(value) !== -1;
    // },
    //人员选择确定
    selectUserConfirm() {
      this.accountingDialogForm.list = [];
      this.$refs.selectUserVueRefs.userList.forEach((item) => {
        this.accountingDialogForm.list.push({
          ...{
            sysUserId: item.id.split("-")[1],
            sysUserName: item.label,
          },
          ...item,
        });
        // this.$refs.tree.setChecked(item.id, false);
      });
      this.$emit("selectUser", this.accountingDialogForm.list);
      this.selectUserShow = false;
      // this.userList = [];
    },
    // //移除人员
    // removePeople(data) {
    //   for (let i = 0; i < this.userList.length; i++) {
    //     if (this.userList[i].id == data.id) {
    //       this.$refs.tree.setChecked(data.id, false);
    //       this.userList.splice(i, 1);
    //     }
    //   }
    // },
    // //递归找出部门下所有人员
    // filterPeople(data, isSelected) {
    //   // this.$refs.tree.setChecked(data.id, isSelected);
    //   if (data.children) {
    //     data.children.forEach((item) => {
    //       this.filterPeople(
    //         { ...item, ...{ deptId: data.id, deptName: data.label } },
    //         isSelected
    //       );
    //     });
    //   } else {
    //     if (isSelected) {
    //       // this.selectedParent(data);
    //       let currentParent = {};
    //       let currentNode = this.$refs.tree.getNode(data);
    //       if (currentNode.parent.key !== undefined) {
    //         this.$refs.tree.setChecked(currentNode.parent, true);
    //         currentParent = {
    //           deptId: currentNode.parent.data.id,
    //           deptName: currentNode.parent.data.label,
    //         };
    //       }
    //       this.userList.push({ ...data, ...currentParent });
    //       this.userList = this.unique(this.userList);
    //     } else {
    //       for (let i = 0; i < this.userList.length; i++) {
    //         if (this.userList[i].id == data.id) {
    //           this.userList.splice(i, 1);
    //         }
    //       }
    //     }
    //   }
    // },
    // //根据id去重
    // unique(arr) {
    //   let newArr = [];
    //   for (let i = 0; i < arr.length; i++) {
    //     if (!newArr.some((item) => item.id == arr[i].id)) {
    //       newArr.push(arr[i]);
    //     }
    //   }
    //   return newArr;
    // },
    //取消选择
    cancel() {
      this.$refs.selectUserVueRefs.$refs.tree.setCheckedKeys([]);
      this.selectUserShow = false;
      this.userList = [];
    },
    // 选择人员
    selectUser({ data, node, nodeComponents }) {
      this.$emit("clickSelectUser", {
        data: data,
        node: node,
        components: nodeComponents,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.selectUserBox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .allUserList {
    width: 240px;
    height: 600px;
    overflow-y: scroll;
  }

  .userList {
    margin-left: 10px;
    padding-left: 10px;
    width: 550px;
    height: 600px;
    overflow-y: scroll;

    // border-left: 1px solid #c9c9c9;
    .userSelectList {
      font-size: 14px;
      font-weight: 600;
    }
  }

  /*修改滚动条样式*/
  div::-webkit-scrollbar {
    width: 2px;
    height: 10px;
    /**/
  }

  div::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }

  div::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
  }

  div::-webkit-scrollbar-thumb:hover {
    background: #333;
  }

  div::-webkit-scrollbar-corner {
    background: #179a16;
  }
}
</style>
