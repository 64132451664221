import request from "@/utils/request";
// v2/pc-docs
//  获取 主体 列表   /pc/wechat/subject
export function getMain(data) {
  return request({
    url: "inner-crm/pc/wechat/subject",
    method: "get",
    params: data,
  });
}
//  获取微信部门and内部用户数据   /pc/wechat/subordinate
export function getBumenMember(data) {
  return request({
    url: "inner-crm/pc/wechat/subordinate",
    method: "get",
    params: data,
  });
}
// 企业微信联系客户统计数据
//  用户联系 图表 数据  /pc/BehaviorData/statistic
export function getBehaviorDatastatistic(data) {
  return request({
    url: "inner-crm/pc/behavior-data/statistic",
    method: "get",
    params: data,
  });
}
// 查询企业微信用户 成员客户统计
export function getUserData(data) {
  return request({
    url: "inner-crm/pc/user/statistic",
    method: "get",
    params: data,
  });
}
// 查询客户列表
export function getCustomerList(data) {
  return request({
    url: "inner-crm/pc/user-externalcontact/customerList",
    method: "get",
    params: data,
  });
}
// 成员联系客户统计 表格 /pc/BehaviorData
export function getBehaviorData(data) {
  return request({
    url: "inner-crm/pc/behavior-data",
    method: "get",
    params: data,
  });
}
// 查询企业微信联系客户统计数据 客户群图表
export function getGroupchatStatisticTuBiao(data) {
  return request({
    url: "inner-crm/pc/groupchat-statistic/statistic",
    method: "get",
    params: data,
  });
}
// 查询企业微信客户群 客户群统计
export function getGroupchatList(data) {
  return request({
    url: "inner-crm/pc/groupchat/statistic",
    method: "get",
    params: data,
  });
}
// 查询企业微信客户群统计数据 /pc/GroupchatStatistic 成员群聊数据统计
export function getGroupchatStatistic(data) {
  return request({
    url: "inner-crm/pc/groupchat-statistic",
    method: "get",
    params: data,
  });
}

// 查询企业微信客户群 客户群统计
export function getGroupchatSelectBy(data) {
  return request({
    url: "inner-crm/pc/groupchat/selectBy",
    method: "post",
    data: data,
  });
}
