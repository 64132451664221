<script>
import { Cascader } from "element-ui";

function cascaderVisable(e, item) {
  const $el = document.querySelectorAll(
    ".el-cascader-panel .el-cascader-node[aria-owns]"
  );
  Array.from($el).map((item) => item.removeAttribute("aria-owns"));
  if (item && item.visible !== undefined && item.visibleChange) {
    item.visibleChange(e);
  }
}

export default {
  extends: Cascader,
  name: "CascaderFix",
  mounted() {
    this.$on("visible-change", cascaderVisable);
  },
};
</script>
<style lang="scss">
.el-scrollbar {
  overflow: hidden;
  position: relative;
}
</style>
