<!-- 选择优惠券 -->
<template>
  <el-dialog
    title="选择优惠券"
    :visible.sync="couponVisible"
    width="60%"
    top="8vh"
    :close="couponHandleClose"
  >
    <div class="dialogMain">
      <div class="left">
        <el-form>
          <el-form-item>
            <el-input
              v-model="couponParams[selectVal]"
              clearable
              placeholder="请输入"
              style="width: 300px"
            >
              <el-select
                slot="prepend"
                v-model="selectVal"
                placeholder="请选择"
                @change="
                  couponParams.couponCode = '';
                  couponParams.couponName = '';
                "
                style="width: 120px"
              >
                <el-option label="优惠券标识" value="couponCode"></el-option>
                <el-option label="优惠券名称" value="couponName"></el-option>
              </el-select>
            </el-input>

            <el-button
              type="primary"
              @click="
                couponParams.pageNumber = 1;
                getCouponList();
              "
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
        <el-s-table
          ref="couponRefs"
          :data="couponList"
          :columns="columnsCoupon"
          :header-row-class-name="!multiple ? 'noneCheck' : ''"
          row-key="couId"
          border
          height="500px"
          @selection-change="couponSelectChange"
        >
        </el-s-table>
        <div class="pagebox" v-if="couponParams.total > 0">
          <el-pagination
            @size-change="couponHandleSizeChange"
            @current-change="couponHandleCurrentPageChange"
            :current-page="couponParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="couponParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="couponParams.total"
            background
          >
          </el-pagination>
        </div>
      </div>
      <div class="right">
        <div>
          <span>已选择</span>
          <el-button
            type="text"
            @click="
              selectCouponList = [];
              couponTags = [];
              $refs.couponRefs.clearSelection();
            "
            >全部取消</el-button
          >
        </div>
        <div class="tagMain">
          <el-tag
            closable
            v-for="item in couponTags"
            :key="item.couId"
            @close="tagClose(couponTags, item.couId, 'couId')"
            >{{ item.title }}</el-tag
          >
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="couponHandleClose">取 消</el-button>
      <el-button type="primary" @click="getChangeCoupon">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { xiaoeCouponList, xiaoeCouponSelectList } from "@/api/hxl/public";
let _this = null;
export default {
  props: ["couponVal", "multiple"],
  data() {
    return {
      // 小鹅通优惠券
      couponVisible: false,
      couponList: [], //优惠券列表
      columnsCoupon: [
        {
          type: "selection",
          "reserve-selection": true,
          width: "60px",
          align: "center",
          "header-align": "center",
          selectable: (row, index) => {
            if (!_this.multiple && _this.selectCouponList.length) {
              return false;
            } else {
              return true;
            }
          },
        },
        { prop: "couId", label: "优惠券标识" },
        { prop: "title", label: "优惠券名称" },
      ],
      selectCouponList: [], //选择优惠券列表
      couponTags: [], //确定后的优惠券列表
      couponParams: {
        couponName: "",
        couponCode: "",
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      selectVal: "couponCode",
      not_foundItem: [],
    };
  },
  created() {
    _this = this;
  },
  methods: {
    // 小鹅通优惠券
    couponHandleClose() {
      this.$refs.couponRefs.clearSelection();
      this.couponVisible = false;
      // this.selectCouponList = [];
      this.couponTags = [];
      this.couponParams.couponName = "";
      this.couponParams.pageNumber = 1;
    },
    getCouponList() {
      console.log(this.couponVal);
      let data = JSON.parse(JSON.stringify(this.couponParams));
      delete data.total;
      xiaoeCouponList(data)
        .then((res) => {
          if (res.code == 200) {
            this.couponList = res.data.data ? res.data.data : [];
            this.$nextTick(() => {
              // Rewrite the code to avoid errors and ensure proper rendering
              // console.log(this.couponVal);
              // if (!this.couponVal.length) {
              //   this.selectCouponList = [];
              //   this.couponTags = [];
              //   this.$refs.couponRefs.clearSelection();
              // }
              this.couponVal.forEach((item) => {
                let foundItem = this.couponList.find((items) => {
                  return (item.couId ? item.couId : item) == items.couId;
                });
                if (foundItem) {
                  this.$refs.couponRefs.toggleRowSelection(foundItem, true);
                }
              });
              this.not_foundItem = this.couponVal.filter((item) => {
                return !this.couponList.some((listItem) => {
                  return (item.couId ? item.couId : item) == listItem.couId;
                });
              });
              if (this.couponVal.length > this.couponTags.length) {
                this.getResidueList();
              }
            });
            this.couponParams.total = res.data.total;
          }
        })
        .catch((err) => {});
    },
    // 获取剩余未选中课程数据
    getResidueList() {
      xiaoeCouponSelectList({
        couIds: this.not_foundItem,
      }).then((res) => {
        res.data.forEach((item) => {
          this.couponTags.push(item);
          this.$refs.couponRefs.toggleRowSelection(item, true);
        });
      });
    },
    couponHandleSizeChange(val) {
      this.couponParams.pageSize = val;
      this.couponParams.pageNumber = 1;
      this.getCouponList();
    },
    couponHandleCurrentPageChange(val) {
      this.couponParams.pageNumber = val;
      this.getCouponList();
    },
    getChangeCoupon() {
      this.couponVisible = false;
      this.couponTags = this.removeDuplicates(
        this.couponTags.concat(this.selectCouponList),
        "couId"
      );
      this.$emit("couponData", this.couponTags);
    },
    couponSelectChange(val) {
      // this.selectCouponList = this.selectCouponList.concat(val);
      this.selectCouponList = val;
      this.selectCouponList = this.removeDuplicates(
        this.selectCouponList,
        "couId"
      );
      if (this.selectCouponList.length > this.couponTags.length) {
        this.selectCouponList.forEach((item) => {
          this.couponTags.push(item);
        });
      } else {
        this.couponTags = this.couponTags.filter((tag) => {
          return this.selectCouponList.some((item) => {
            return JSON.stringify(item) === JSON.stringify(tag);
          });
        });
      }
      this.couponTags = this.removeDuplicates(this.couponTags, "couId");
    },
    removeDuplicates(array, field) {
      return array.filter((item, index, self) => {
        return index === self.findIndex((t) => t[field] === item[field]);
      });
    },
    tagClose(arr, id, key = "id") {
      let index = arr.findIndex((item) => item[key] === id);
      if (index !== -1) {
        arr.splice(index, 1);
        if (this.selectCouponList[index]) {
          this.$refs.couponRefs.toggleRowSelection(
            this.selectCouponList[index]
          );
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialogMain {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .right {
    margin-top: 18px;
    width: 30%;
    .tagMain {
      padding-left: 5px;
      height: 500px;
      overflow-y: scroll;
      border: 1px solid #dfe6ec;
      .el-tag {
        margin-right: 5px;
      }
    }
  }
  ::v-deep .el-table .noneCheck th.el-table__cell > .cell {
    .el-checkbox {
      display: none;
    }
  }
}
</style>
