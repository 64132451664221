<template>
  <el-dialog
    title="选择用户"
    :visible.sync="selectUserShow"
    top="10vh"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="userSerch" v-show="corpSelectShow">
      <el-form ref="form" inline>
        <el-form-item>
          <el-select
            v-model="searchType"
            placeholder="请选择"
            style="width: 200px"
            @change="searchBtn()"
          >
            <el-option label="按主体展示" :value="1" />
            <el-option label="按组织架构展示" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="corpList"
            multiple
            collapse-tags
            filterable
            style="width: 250px"
            @change="getList"
          >
            <el-option
              v-for="(item, index) in allCorpList"
              :key="index"
              :label="item.corpName"
              :value="item.corpId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="输入关键字" v-model="filterText" />
        </el-form-item>
      </el-form>
    </div>
    <div class="selectUserBox">
      <div class="allUserList">
        <div class="tree_box">
          <el-tree
            v-if="searchType === 1"
            key="searchType1"
            class="filter-tree"
            style="width: 100%"
            :data="allCorpUserList"
            :filter-node-method="filterNode"
            :default-expanded-keys="['1']"
            node-key="id"
            ref="tree1"
            :show-checkbox="showCheckbox"
            @check-change="checkChange1"
            @node-click="nodeClick"
          >
          </el-tree>
          <el-tree
            v-else-if="searchType === 2"
            key="searchType2"
            class="filter-tree"
            style="width: 100%"
            :data="allUserList"
            :filter-node-method="filterNode"
            :default-expanded-keys="['1']"
            node-key="id"
            ref="tree"
            :show-checkbox="showCheckbox"
            @check-change="checkChange"
            @node-click="nodeClick"
          >
          </el-tree>
        </div>
      </div>
      <div class="userList">
        <div class="userSelectList">已选择</div>
        <div class="tag_box">
          <el-tag
            v-for="(item, index) in userList"
            :key="index"
            closable
            style="margin: 4px 8px"
            @close="removePeople(item)"
          >
            {{ item.label }}
          </el-tag>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="selectUserConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {
  getDeptWechatsAll,
  getSubjectList,
  allCorpIdWechat,
} from "@/api/hxl/public.js";
export default {
  props: {
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    checkWechat: {
      type: Boolean,
      default: true,
    },
    corpSelectShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchType: 1,
      selectUserShow: false,
      userList: [], //已选用户列表
      allUserList: [], //所有用户列表
      allCorpUserList: [], //所有用户列表 根据主体
      filterText: "",
      accountingDialogForm: {},
      corpId: null,
      corpList: [],
      allCorpList: [],
      selectKeys: [], //选中值的id
      tempUserList: [], //临时用户列表
    };
  },
  created() {
    // if (this.corpId) {
    //   getCropUser({ corpId: corpId })
    //     .then((res) => {
    //       this.allUserList = res.data;
    //       this.setDisablePeople(this.allUserList[0].children);
    //     })
    //     .catch((err) => {});
    // } else {
    // }
    getSubjectList().then((res) => {
      this.allCorpList = res.data;
    });
  },
  watch: {
    filterText(val) {
      if (this.searchType === 1) {
        this.$refs.tree1.filter(val);
      } else if (this.searchType === 2) {
        this.$refs.tree.filter(val);
      }
    },
  },
  methods: {
    searchBtn(tempUserList) {
      this.tempUserList = tempUserList || [];
      this.userList = [];
      if (this.searchType == 1) {
        this.$nextTick(() => {
          this.$refs.tree1.setCheckedKeys([]);
        });
      } else {
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys([]);
        });
      }
      this.getList();
    },
    getList() {
      if (this.searchType == 1) {
        this.allCorpIdWechat();
      } else {
        this.getUserWatch();
      }
    },
    //禁用无微信号的用户
    setDisablePeople(data) {
      data.forEach((item, index) => {
        if (
          (item.type == 1 || item.type == 2) &&
          (!item.children || !item.children.length) &&
          this.checkWechat
        ) {
          item.disabled = true;
        }
        if (!this.checkWechat && item.type == 1) {
          delete item.children;
        }
        if (item.type == 0) {
          item.label = item.pname + "-" + item.label;
        }
        if ([0, 1].includes(item.type)) {
          this.tempUserList.forEach((items, index) => {
            if (items.userId == item.userId && items.corpId == item.corpId) {
              this.selectKeys.push(item.id);
              this.$set(this.userList, index, item);
            }
          });
        }
        if (item.children) {
          this.setDisablePeople(item.children);
        }
      });
    },
    checkChange(data, checked, indeterminate) {
      //获取所有选中的节点
      let res = this.$refs.tree.getCheckedNodes();
      this.userList = [];
      res.forEach((item) => {
        if (item.type == 0 && this.checkWechat) {
          this.userList.push(item);
        } else if (item.type == 1 && !this.checkWechat) {
          this.userList.push(item);
        }
        this.selectKeys = this.userList.map((item) => {
          return item.id;
        });
      });
    },
    checkChange1(data, checked, indeterminate) {
      //获取所有选中的节点
      let res = this.$refs.tree1.getCheckedNodes(); //获取所有勾选的值
      this.userList = [];
      res.forEach((item) => {
        this.userList.push(item);
      });
      this.selectKeys = this.userList.map((item) => {
        return item.id;
      });
    },
    // 获取用户数据
    getUserWatch() {
      this.filterText = "";
      getDeptWechatsAll({
        corpList: this.corpList ? this.corpList.toString() : this.corpList,
      })
        .then((res) => {
          this.allUserList = res.data[0].children;
          this.allUserList.forEach((item) => {
            this.setDisablePeople(item.children);
          });
          this.$refs.tree.setCheckedKeys(this.selectKeys, true);
        })
        .catch((err) => {});
    },
    // 获取用户数据 根据主体
    allCorpIdWechat() {
      this.filterText = "";
      allCorpIdWechat({
        corpId: this.corpList ? this.corpList.toString() : this.corpList,
      })
        .then((res) => {
          const tempCorpUserMap = {};
          this.allCorpUserList = this.resetCorpWechat(
            res.data,
            tempCorpUserMap
          );
          // this.allUserList.forEach((item) => {
          //   this.setDisablePeople(item.children);
          // });
          this.userList = [];
          this.selectKeys = this.tempUserList.map((item) => {
            const user = tempCorpUserMap[item.corpId]?.find(
              (row) => row.userId === item.userId
            );
            if (user) this.userList.push(user);
            return item.corpId + "-" + item.userId;
          });
          console.log(
            "this.userList",
            this.userList,
            "this.selectKeys",
            this.selectKeys
          );

          this.$refs.tree1.setCheckedKeys(this.selectKeys, true);
        })
        .catch((err) => {});
    },
    resetCorpWechat(arr, tempCorpUserMap = {}) {
      return arr.map((row) => {
        if (row.children) {
          row.children = this.resetCorpWechat(row.children, tempCorpUserMap);
        } else {
          row.userId = row.id;
          row.id = row.corpId + "-" + row.id;
          if (!tempCorpUserMap[row.corpId]) {
            tempCorpUserMap[row.corpId] = [];
          }
          tempCorpUserMap[row.corpId].push(row);
        }
        return row;
      });
    },
    //用户选择变更
    // selectUserChange(data, indeterminate) {
    // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
    // let selected = indeterminate.checkedKeys.indexOf(data.id); // -1未选中
    // console.log(selected,data.id,indeterminate.checkedKeys)
    // 选中
    // if (selected !== -1) {
    //   // 子节点只要被选中父节点就被选中
    //   // 统一处理子节点为相同的勾选状态
    //   this.filterPeople(data, true);
    //   // this.selectedParent(data)
    // } else {
    //   // 未选中 处理子节点全部未选中
    //   this.filterPeople(data, false);
    // }
    // },
    //用户搜索筛选
    filterNode(value, data) {
      // console.log(value, data);
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    //人员选择确定
    selectUserConfirm() {
      this.$emit("selectUser", {
        userList: this.userList,
        corpList: this.corpList,
      });
      this.selectUserShow = false;
    },
    //移除人员
    removePeople(data) {
      for (let i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id == data.id) {
          if (this.searchType === 1) {
            this.$refs.tree1.setChecked(data.id, false);
          } else {
            this.$refs.tree.setChecked(data.id, false);
          }
          this.userList.splice(i, 1);
        }
      }
    },
    //递归找出部门下所有人员
    // filterPeople(data, isSelected) {
    //   if (data.children.length) {
    //     data.children.forEach((item) => {
    //       this.filterPeople(
    //         { ...item, ...{ deptId: data.id, deptName: data.label } },
    //         isSelected
    //       );
    //     });
    //   } else {
    //     if (isSelected) {
    //       let currentParent = {};
    //       let currentNode = this.$refs.tree.getNode(data);
    //       if (currentNode.parent.key !== undefined) {
    //         this.$refs.tree.setChecked(currentNode.parent, true);
    //       }
    //       this.userList.push(data);
    //       this.userList = this.unique(this.userList);
    //     } else {
    //       for (let i = 0; i < this.userList.length; i++) {
    //         if (this.userList[i].id == data.id) {
    //           this.userList.splice(i, 1);
    //         }
    //       }
    //     }
    //   }
    // },
    //根据id去重
    unique(arr) {
      let newArr = [];
      for (let i = 0; i < arr.length; i++) {
        if (!newArr.some((item) => item.id == arr[i].id)) {
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    //取消选择
    cancel() {
      if (this.searchType === 1) {
        this.$refs.tree1.setCheckedKeys([]);
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
      this.userList = [];
      this.selectUserShow = false;
    },
    // 单选选择节点方法
    nodeClick(data) {
      if (
        this.showCheckbox ||
        data.type == 2 ||
        (data.type == 1 && this.checkWechat)
      )
        return;
      this.$emit("selectUser", {
        userList: [data],
        corpList: this.checkWechat ? [data.corpId] : null,
      });
      this.selectUserShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.userSerch {
  display: flex;
  min-width: 280px;
  .el-input {
    width: 120px;
  }
  .el-select {
    width: 240px;
  }
}
.selectUserBox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .allUserList {
    width: 40%;
    .tree_box {
      // min-width: 30%;
      height: 600px;
      overflow-x: scroll;
      overflow-y: scroll;
    }
  }

  .userList {
    margin-left: 10px;
    padding-left: 10px;
    width: 60%;
    height: 600px;
    overflow-y: scroll;

    .tag_box {
      margin-top: 20px;
    }

    // border-left: 1px solid #c9c9c9;
    .userSelectList {
      font-size: 14px;
      font-weight: 600;
    }
  }

  /*修改滚动条样式*/
  div::-webkit-scrollbar {
    width: 2px;
    height: 10px;
    /**/
  }

  div::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }

  div::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
  }

  div::-webkit-scrollbar-thumb:hover {
    background: #333;
  }

  // div::-webkit-scrollbar-corner {
  //   background: #179a16;
  // }
}
::v-deep .el-dialog__body {
  padding-top: 5px;
}
</style>
