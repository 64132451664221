//柱状图
<template>
  <div class="chartBox" :id="barId"></div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
} from "echarts/components";
import { BarChart, LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  TitleComponent,
]);
export default {
  name: "echartBar",
  // props: ["nowOptions"],
  props: {
    nowOptions: {
      type: Object,
      default: {},
    },
    barId: {
      type: String,
      default: "barChatr",
    },
  },
  data() {
    return {
      chartsObj: null,
      option: {},
    };
  },
  created() {
    this.option = this.nowOptions;
  },
  mounted() {
    let chartDom = document.getElementById(this.barId);
    this.chartsObj = echarts.init(chartDom);
    this.option && this.chartsObj.setOption(this.option, true);
  },
  watch: {
    nowOptions: {
      handler: function (newVal, oldVal) {
        this.option = newVal;
        this.option && this.chartsObj.setOption(this.option, true);
        if (this.option?.yAxis?.data) {
          const len = this.option.yAxis.data.length;
          setTimeout(() => {
            this.chartsObj.resize();
          }, 100);
        }
      },
      deep: true,
    },
  },
  activated() {
    this.option = this.nowOptions;
    this.option && this.chartsObj.setOption(this.option, true);
  },
  destroyed() {
    this.chartsObj.clear();
  },
};
</script>
<style lang="scss" scoped>
.chartBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
