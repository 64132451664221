var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择用户",
        visible: _vm.selectUserShow,
        width: "800px",
        top: "10vh",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": function () {
          _vm.$refs.selectUserVueRefs.$refs.tree.setCheckedKeys([])
          _vm.selectUserShow = false
        },
      },
      on: {
        "update:visible": function ($event) {
          _vm.selectUserShow = $event
        },
        opened: _vm.handleOpen,
      },
    },
    [
      _c("selectuserVue", {
        ref: "selectUserVueRefs",
        attrs: {
          showCheckbox: _vm.showCheckbox,
          allDepts: _vm.allUserList,
          isRadioSelect: _vm.isRadioSelect,
          defaultProps: _vm.defaultProps,
        },
        on: { clickSelectUser: _vm.selectUser },
      }),
      _vm._v(" "),
      _vm.showCheckbox
        ? _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.selectUserConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }